import { TextField, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useState, useEffect, useCallback } from 'react';
import { ReactComponent as SearchIcon } from '../../../components/icons/SearchIcon.svg';
import { getDisplayTextFromMap, getNestedValue } from '../../utils/utils';
import EntityTypeFilterTabs from '../../atoms/entitySearch/entityTypeFilterTabs';
import StaticChip from '../../atoms/staticChip';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 1
    },
    '& .MuiInputBase-input': {
      height: '10px !important',
      fontFamily: 'Satoshi !important'
    }
  },
  searchIcon: {
    marginRight: '4px',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: '#80a6ff'
  },
  inputLabel: {
    fontFamily: 'Satoshi !important',
    color: '#000000 !important',
    opacity: 0.6
  },
  optionTypo: {
    fontSize: '14px',
    fontWeight: 400,
    margin: '4px 0px'
  }
}));

const defaultFn = () => {};

const AutocompleteSearch = ({ searchableProps }) => {
  const {
    getAutocompleteOptions = defaultFn,
    isPresistSearchValue = false,
    onAutoCompleteChange = defaultFn,
    queryParamName = 'search',
    tabOptions = [],
    selectedTab = '',
    onSelectedTabOption,
    APIDependency = [],
    handleOnBlur
  } = searchableProps;

  const location = useLocation();
  // const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const classes = useStyles();

  const getOptions = async (input, page, apDependency) => {
    const dataToSend = [input, page, ...apDependency];
    setLoading(true);
    try {
      const { newOptions, total_records } = await getAutocompleteOptions(dataToSend);

      if (page === 1) {
        setOptions(newOptions);
        setScrollPosition(0);
        const listbox = document.getElementById('listbox-id');
        if (listbox) {
          listbox.scrollTop = 0;
        }
      } else {
        setOptions((prevOptions) => [...prevOptions, ...newOptions]);
      }

      setHasMore(newOptions.length > 0 && options.length !== total_records);

      const selected = newOptions.find((option) => option.entity_name === input);
      if (selected) {
        setSelectedItem(selected);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onPageScroll = async (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const isBottom = scrollHeight - scrollTop - (options?.length || 0) * 4 <= clientHeight + 10;
    if (isBottom && hasMore && !isFetchingMore) {
      setScrollPosition(scrollTop);
      setIsFetchingMore(true);
      await handleScroll();
      setIsFetchingMore(false);
    }
  };

  useEffect(() => {
    const listbox = document.getElementById('listbox-id');
    if (listbox && scrollPosition > 0) {
      listbox.scrollTop = scrollPosition;
    }
  }, [options, scrollPosition]);

  const fetchOptions = useCallback(
    async (input, page) => {
      if (!input) return;
      getOptions(input, page, [...APIDependency]);
    },
    [...APIDependency]
  );

  useEffect(() => {
    if (isPresistSearchValue) {
      const query = new URLSearchParams(location.search).get([queryParamName]);
      setInputValue(query || '');
    }
  }, [location.search, isPresistSearchValue]);

  useEffect(() => {
    if (!inputValue) {
      setOptions([]);
      setSelectedItem(null);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      fetchOptions(inputValue, 1);
      setPage(1);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, fetchOptions]);

  useEffect(() => {
    onAutoCompleteChange(selectedItem);
    // if (selectedItem) {
    //   // if (isPresistSearchValue) {
    //     // updateParamsInURL({navigate, location, queryParamName, selectedItem.entity_name})

    //   //   // const params = new URLSearchParams(location.search);
    //   //   // params.set([queryParamName], selectedItem.entity_name);
    //   // }
    // }
  }, [selectedItem, isPresistSearchValue]);

  const handleScroll = async () => {
    await getOptions(inputValue, page + 1, [...APIDependency]);
    setPage((prev) => prev + 1);
  };

  const handleFocus = () => {
    setScrollPosition(0);
  };

  const onSelectedSearch = (event, value) => {
    setSelectedItem(value);
  };

  const onBlur = () => {
    handleOnBlur();
  };

  return (
    <>
      <Autocomplete
        options={options}
        className={classes.root}
        getOptionLabel={(option) => getNestedValue(option, 'entity_name') || ''}
        isOptionEqualToValue={(option, value) => option.entity_id === value.entity_id}
        onChange={onSelectedSearch}
        onInputChange={(event, value) => {
          setInputValue(value);
          if (!value) setOptions([]);
        }}
        inputValue={inputValue}
        loading={loading}
        onClose={() => setOptions([])}
        ListboxProps={{ onScroll: onPageScroll, id: 'listbox-id' }}
        renderInput={(params) => (
          <TextField
            onFocus={handleFocus}
            onBlur={onBlur}
            {...params}
            variant="outlined"
            placeholder="Search"
            label="Search"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <SearchIcon className={classes.searchIcon} />
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            InputLabelProps={{ classes: { root: classes.inputLabel } }}
          />
        )}
        renderOption={(option) => (
          <>
            <Typography className={classes.optionTypo}>
              {getNestedValue(option, 'entity_name')}
            </Typography>
            <StaticChip displayText={getDisplayTextFromMap(option, 'type', [])} />
          </>
        )}
        ListboxComponent={(props) => (
          <EntityTypeFilterTabs
            tabsOptions={tabOptions}
            props={props}
            setFilterTab={onSelectedTabOption}
            selectedTab={selectedTab}
          />
        )}
      />
      {/* <div>Selected Item: {selectedItem ? JSON.stringify(selectedItem) : 'No item selected'}</div> */}
    </>
  );
};

export default AutocompleteSearch;
