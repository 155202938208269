import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem
} from '@mui/material';
import { postApi, putApi } from '../../../services/token';
import { useEffect, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Close, ErrorOutline } from '@mui/icons-material';
import SearchComp from '../../atoms/searchComp';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    borderRadius: '16px !important',
    height: '566px',
    width: '500px'
  },
  autocompleteInput: {
    height: '4px !important'
  },
  autocompleteInputDiv: {
    borderRadius: '10px!important'
  },
  autocompleteRoot: {
    margin: '0px 10px'
  },
  closeBtn: {
    position: 'absolute !important',
    right: '10px',
    top: '8px'
  },
  autoCompletRoot: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input': {
      height: '1px !important'
    }
  },
  companyList: {
    height: '350px',
    overflow: 'auto',
    margin: '2px 0px !important'
  },
  companyLi: {
    paddingLeft: '0px !important',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    '&:hover': {
      borderRadius: '3px',
      opacity: '0.9',
      background: 'rgb(248, 250, 253) !important'
    }
  },
  saveBtn: {
    background: 'linear-gradient(90deg,#004CFF,#06BCC1)'
  },
  removeLink: {
    fontWeight: 'lighter',
    fontSize: '13px',
    cursor: 'pointer',
    color: 'blue',
    margin: '0px 10px',
    '&:hover': {
      color: theme.palette.error.main,
      textDecoration: 'underline'
    }
  }
}));

const url = process.env.REACT_APP_API;
const companyUrl = process.env.REACT_APP_COMPANY_API;
const AddCompaniesPopOver = ({
  openState = false,
  handleClose = () => {},
  watchlistedCompanies = [],
  watchlistId = '',
  trigger = () => {}
}) => {
  const classes = useStyles();
  const [companyNameOptions, setCompanyNameOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const getCompanyList = async () => {
    try {
      const companyListData = await postApi(
        `${companyUrl}/v1/company_tear_sheet/template_companies`,
        {}
      );
      if (companyListData && companyListData.data) {
        const parsedData = companyListData.data.data.map((comp) => ({
          key: comp.cin,
          value: comp.name
        }));
        setAllCompanies(parsedData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (key, value) => {
    let updatedSelectedValues = JSON.parse(JSON.stringify([...selectedValues]));
    const index = updatedSelectedValues.findIndex((comp) => comp.key === key);
    setErrorMessage('');
    if (index == -1) {
      updatedSelectedValues.push({
        key: key,
        value: value
      });
    } else {
      updatedSelectedValues.splice(index, 1);
    }
    setSelectedValues(updatedSelectedValues);
  };
  useEffect(() => {
    let option = JSON.parse(JSON.stringify(allCompanies));
    option = option.filter((comp) => !watchlistedCompanies.some((exist) => exist.cin === comp.key));
    setCompanyNameOptions(option);
  }, [watchlistedCompanies, allCompanies]);
  useEffect(() => {
    getCompanyList();
  }, []);
  const handleSave = async () => {
    setErrorMessage('');
    if (selectedValues.length == 0) {
      setErrorMessage('Zero companies selected');
      return;
    }
    try {
      const payLoad = {};
      payLoad.add_cins = selectedValues.map((obj) => obj.key);
      await putApi(`${url}/v1/user/watchlist/${watchlistId}`, payLoad);
      setSelectedValues([]);
      trigger();
      handleClose();
    } catch {
      setErrorMessage(t('addToWatchlistPopUp.errorMesage'));
    }
  };
  const handleSearchChange = (event, value) => {
    setErrorMessage('');
    setSelectedValues(value);
  };
  const searchCompProps = {
    handleChange: handleSearchChange,
    options: companyNameOptions,
    selectedValues: selectedValues,
    placeholder: 'Select Company'
  };
  return (
    <Dialog open={openState} classes={{ paper: classes.mainDiv }}>
      <IconButton
        className={classes.closeBtn}
        onClick={() => {
          setSelectedValues([]);
          handleClose();
        }}
      >
        <Close />
      </IconButton>
      <DialogTitle style={{ margin: '20px 0px 0px' }}>
        <Typography variant="h5">Add to watchlist</Typography>
      </DialogTitle>
      <DialogContent>
        <SearchComp {...searchCompProps} />
        <List className={classes.companyList}>
          {selectedValues &&
            selectedValues.map((comp) => (
              <ListItem key={comp.cin} className={classes.companyLi}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#000000B2',
                    margin: '0px 10px'
                  }}
                >
                  {comp?.value}
                </Typography>
                <Typography
                  className={classes.removeLink}
                  onClick={() => handleChange(comp.key, comp.vale)}
                >
                  remove
                </Typography>
              </ListItem>
            ))}
        </List>
        {errorMessage && (
          <Box
            m={1}
            style={{
              background: '#FDF6F6',
              borderRadius: '5px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              bottom: '55px',
              right: '17%',
              width: '60%'
            }}
          >
            <ErrorOutline
              style={{
                marginLeft: '13px', // Set margin left to 13px
                marginRight: '5px', // Set margin right to 1px
                color: '#EB5B55' // Set color of the icon to #3C962A
              }}
            />
            <Typography
              style={{
                fontSize: '14px',
                color: '#EB5B55',
                marginRight: '15px'
              }}
            >
              {errorMessage}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions style={{ display: 'flex', flexDirection: 'column', padding: '0px 30px 16px' }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.saveBtn}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCompaniesPopOver;
