import React, { useState, useContext, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import { AuthContext } from '../../components/auth/contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { getNameFunc } from '../shared/utils/usernameFunc';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import LogoutIcon from '@mui/icons-material/Logout';
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { setResetState } from '../shared/action';
// import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { ReactComponent as DocIngestIcon } from '../../components/icons/DocIngestIcon.svg';
import { ReactComponent as MyWatchlistIcon } from '../../components/icons/MyWatchlistIcon.svg';
import { ReactComponent as ProfileIcon } from '../../components/icons/ProfileIcon.svg';
import { ReactComponent as SignOutIcon } from '../../components/icons/SignOutIcon.svg';
import StepPopover from '../tourGuide';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    height: 58,
    '& > *:first-child': {
      marginRight: '10px'
    },
    fontFamily: 'Satoshi',
    fontWeight: 'normal'
  },
  backgroundStep: {
    '&::before': {
      border: '10px solid transparent',
      content: '""',
      position: 'absolute',
      bottom: '100%',
      left: '-13px',
      top: '30px',
      borderWidth: '10px',
      borderStyle: 'solid',
      borderColor: 'transparent transparent #ffffff transparent',
      transform: 'rotate(90deg)',
      zIndex: 9999
    },
    '& ul': {
      padding: '0!important',
      '& li:not(:first-child)': {
        'background-color': 'rgba(0, 0, 0, 0.5)!important'
      }
    }
  },
  white: {
    background: '#ffffff'
  },
  menuItemStep: {
    overflow: 'visible!important',
    'border-radius': '20px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)!important'
  },
  highlightLi: {
    backgroundColor: 'transparent!important',
    'border-radius': '32px'
  }
}));

const CustomMenuItem = (props) => {
  const { setAnchorElPopover } = props;
  const documentRef = useRef(null);

  useEffect(() => {
    if (documentRef.current) {
      setAnchorElPopover(documentRef.current);
    }
  }, [documentRef.current]);

  return (
    <MenuItem {...props} ref={documentRef}>
      {props.children}
    </MenuItem>
  );
};

function ProfilePopup({
  storeCurrentStep,
  storeShowTourGuide,
  step4Props = null,
  setStep4Props = () => {},
  parentHandleClose,
  skip
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [nameChar, setNameChar] = useState(null);
  const dispatch = useDispatch();

  const menuIconRef = useRef(null);
  const stepEnabled = step4Props && storeCurrentStep == 5 && storeShowTourGuide;

  useEffect(() => {
    const getName = async () => {
      const { nameCharVar } = await getNameFunc(auth);
      setNameChar(nameCharVar);
    };
    getName();
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPopover, setAnchorElPopover] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleClosePopOver = (isParentClose = true) => {
    setAnchorEl(null);
    setAnchorElPopover(null);
    setStep4Props(null);
    if (isParentClose) parentHandleClose();
  };

  useEffect(() => {
    if (stepEnabled) {
      setAnchorEl(menuIconRef.current);
    } else {
      handleClosePopOver(false);
    }
  }, [step4Props, stepEnabled]);

  const navigate = useNavigate();
  const logOut = () => {
    auth.signOut();
    dispatch(setResetState());
    navigate('/');
  };

  const profile = () => {
    handleClose();
    navigate('/profile');
  };

  const watchlistedCompanies = () => {
    handleClose();
    navigate('/watchlist-company');
  };

  const docUploadManage = () => {
    handleClose();
    navigate('/document-upload/manage');
  };

  return (
    <>
      {stepEnabled && (
        <StepPopover
          hideBackdrop={true}
          customStyle={{ left: '-54px' }}
          value={anchorElPopover}
          currentStep={step4Props}
          onClose={handleClosePopOver}
          skip={skip}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      )}
      <Avatar
        sx={{
          height: '40px',
          width: '40px',
          border: '1px solid #7DA3FD',
          backgroundColor: '#E3EBFD',
          color: '#0C0C0C',
          fontSize: '15px',
          cursor: 'pointer'
        }}
        style={{ fontFamily: 'Satoshi', fontWeight: 'bold' }}
        onClick={handleClick}
        ref={menuIconRef}
      >
        {nameChar}
      </Avatar>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: `${stepEnabled ? classes.backgroundStep : classes.white} ${classes.menuItemStep}`
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        BackdropProps={
          stepEnabled
            ? {
                classes: {
                  root: classes.backdrop
                }
              }
            : {}
        }
      >
        {process.env.REACT_APP_ENABLE_DOCUMENT_UPLOAD === 'true' && (
          <CustomMenuItem
            onClick={docUploadManage}
            style={{ fontFamily: 'Satoshi', fontWeight: 'normal' }}
            className={`${stepEnabled ? classes.highlightLi : ''} ${classes.menuItem}`}
            setAnchorElPopover={setAnchorElPopover}
          >
            <DocIngestIcon />
            {t('headder.ingestDoc')}
          </CustomMenuItem>
        )}

        <MenuItem
          onClick={profile}
          style={{ fontFamily: 'Satoshi', fontWeight: 'normal' }}
          className={classes.menuItem}
        >
          <ProfileIcon />
          {t('headder.profileButton')}
        </MenuItem>
        <MenuItem
          onClick={watchlistedCompanies}
          style={{ fontFamily: 'Satoshi', fontWeight: 'normal' }}
          className={classes.menuItem}
        >
          <MyWatchlistIcon />
          {t('headder.watchListCompany')}
        </MenuItem>

        <MenuItem
          onClick={logOut}
          style={{ fontFamily: 'Satoshi', fontWeight: 'normal' }}
          className={classes.menuItem}
        >
          <SignOutIcon />
          {t('headder.signOutButton')}
        </MenuItem>
      </Menu>
    </>
  );
}
export default ProfilePopup;
