import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { postApi, putApi } from '../../services/token';
import SearchComp from '../atoms/searchComp';
import { makeStyles } from '@material-ui/core/styles';
import LoadingPopover from '../shared/loadingPopover/loadingPopover';
import ErrorToast from '../atoms/errorToast';
import SuccessToast from '../atoms/successToast';
import { Button, Grid } from '@mui/material';
// import CustomChip from '../atoms/chip';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as FileUploadIcon } from '../../components/icons/FileUploadIcon.svg';
import { ReactComponent as FileRemoveIcon } from '../../components/icons/FileRemoveIcon.svg';
import { v4 as uuidv4 } from 'uuid';
import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../atoms/select';

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    width: '20%',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      margin: '15px 0 23px 0'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '40%'
    },
    float: 'right'
  },
  uploadText: {
    fontFamily: 'Satoshi',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'center'
  },
  uploadHeadderFiles: {
    fontFamily: 'Satoshi',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'left'
  },
  uploadFilesName: {
    fontFamily: 'Satoshi',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    textAlign: 'left',
    wordBreak: 'break-all',
    color: '#171717',
    paddingTop: '16px'
  },
  uploadHeadderTags: {
    fontFamily: 'Satoshi',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'center'
  },
  uploadHeadderDelete: {
    fontFamily: 'Satoshi',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'right'
  },
  uploadCheckBoxTest: {
    fontFamily: 'Satoshi',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '18.9px',
    textAlign: 'left'
  },
  uploadButton: {
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px'
  },
  cancleButton: {
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #797F8D, #797F8D)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#FFFFFF',
      zIndex: -1,
      transition: '200ms'
    }
  },
  fileGrid: {
    padding: '60px 0px 0px 0px'
  },
  uploadBox: {
    height: '160px'
  },
  removeFile: {
    fontFamily: 'Satoshi',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'right',
    padding: '16px 5px 5px 0px'
  },
  uploadHeadderTagsMain: {
    fontFamily: 'Satoshi',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'center',
    padding: '16px 5px 5px 0px'
  }
}));

const UploadFile = ({ propsData }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const classes = useStyles();
  const url = process.env.REACT_APP_INGESTION_API_URL;
  const { allCompanies, allSectors } = propsData;
  const navigate = useNavigate();
  useEffect(() => {
    const successTimer = setTimeout(() => {
      setSuccessMessage(null);
    }, 7000);

    const errorTimer = setTimeout(() => {
      setErrorMessage(null);
    }, 7000);

    return () => {
      clearTimeout(successTimer);
      clearTimeout(errorTimer);
    };
  }, [successMessage, errorMessage]);

  const handleRemoveFile = (uuid) => {
    const updatedFiles = files.filter((file) => file.uuid !== uuid);
    setFiles(updatedFiles);
  };

  const handleCheckboxChange = (uuid, associationType) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === uuid) {
        const updatedFile = { ...file };
        const index = updatedFile.association_types.indexOf(associationType);
        if (index !== -1) {
          updatedFile.association_types.splice(index, 1);
        } else {
          updatedFile.association_types[0] = associationType;
        }

        return updatedFile;
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      const payload = createPayload();

      const response = await postApi(`${url}/v1/document/ingestion`, payload);

      const uploadUrls = response.data.data.upload_urls;

      for (const filename in uploadUrls) {
        const uploadKey = uploadUrls[filename].upload_key;

        const fileIndex = files.findIndex((file) => file.name === filename);
        if (fileIndex !== -1) {
          const file = files[fileIndex].file;
          const formData = new FormData();
          formData.append(filename, file);

          try {
            await putApi(uploadKey, formData, {
              headers: {
                skipAuth: 'true'
              }
            });
            navigate('/document-upload/manage');
          } catch (error) {
            setErrorMessage(`Error uploading file ${filename}.`);
          }
        }
      }

      setSuccessMessage('Files uploaded successfully.');
      setIsLoading(false); // Hide loader
      setFiles([]);
    } catch (error) {
      setErrorMessage(error.response.data.error.detail);
      setIsLoading(false); // Hide loader
    }
  };

  const createPayload = () => {
    const payload = {
      upload_type: 'file',
      file_details: files.map((file) => {
        let association_types = file.association_types;
        let size = file.size;
        const source_link = file.name;
        let association_details = {};
        const document_type = file.document_type;
        if (file.association_types.includes('webLinks')) {
          association_types = ['no_association_type'];
        } else {
          if (
            file.sectorSelectedValues &&
            file.sectorSelectedValues.length > 0 &&
            file.association_types.includes('themes')
          ) {
            association_details['themes_list'] = file.sectorSelectedValues.map(
              (sector) => sector.key
            );
          }

          if (
            file.companySelectedValues &&
            file.companySelectedValues.length > 0 &&
            file.association_types.includes('company')
          ) {
            association_details['cin_list'] = file.companySelectedValues.map(
              (company) => company.key
            );
          }
        }
        return {
          association_types,
          size,
          source_link,
          association_details,
          document_type
        };
      })
    };
    return payload;
  };

  const validateFileTags = () => {
    const missingDocumentTypeFiles = files.filter((file) => !file.document_type);
    const invalidTagAssociationFiles = files.filter((file) => {
      if (file.association_types.includes('miscellaneous') || file.document_type === 'webLinks') {
        return false;
      } else if (file.association_types.includes('company')) {
        return file.companySelectedValues.length === 0;
      } else if (file.association_types.includes('themes')) {
        return file.sectorSelectedValues.length === 0;
      }
      return true;
    });

    if (missingDocumentTypeFiles.length === 0 && invalidTagAssociationFiles.length === 0) {
      handleUpload();
    } else {
      let errorMessage = '';
      if (missingDocumentTypeFiles.length > 0) {
        const missingDocumentTypeFileNames = missingDocumentTypeFiles.map((file) => file.name);
        errorMessage += `Please associate file type to ${missingDocumentTypeFileNames.join(
          ', '
        )}. `;
      } else if (invalidTagAssociationFiles.length > 0) {
        const invalidTagAssociationFileNames = invalidTagAssociationFiles.map((file) => file.name);
        errorMessage += `Please associate valid tags to ${invalidTagAssociationFileNames.join(
          ', '
        )}.`;
      }
      setErrorMessage(errorMessage);
    }
  };

  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      uuid: uuidv4(),
      association_types: [],
      companySelectedValues: [],
      document_type: 'reports',
      sectorSelectedValues: [],
      preview: URL.createObjectURL(file),
      file: file
    }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, application/pdf',
    onDrop
  });

  const handleDeleteCompanyChip = (value, uuid) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === uuid) {
        const indexToRemove = file.companySelectedValues.findIndex((item) => item.key === value);
        if (indexToRemove !== -1) {
          const updatedValues = [...file.companySelectedValues];
          updatedValues.splice(indexToRemove, 1);
          return { ...file, companySelectedValues: updatedValues };
        }
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const handleDeleteSectorChip = (value, uuid) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === uuid) {
        const indexToRemove = file.sectorSelectedValues.findIndex((item) => item.key === value);
        if (indexToRemove !== -1) {
          const updatedValues = [...file.sectorSelectedValues];
          updatedValues.splice(indexToRemove, 1);
          return { ...file, sectorSelectedValues: updatedValues };
        }
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const handleCompanyChange = (event, value, uuid) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === uuid) {
        return { ...file, companySelectedValues: value };
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const handleSectorChange = (event, value, uuid) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === uuid) {
        return { ...file, sectorSelectedValues: value };
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const searchCompProps = (uuid) => ({
    handleChange: (event, value) => handleCompanyChange(event, value, uuid),
    options: allCompanies,
    selectedValues: files.find((file) => file.uuid === uuid)?.companySelectedValues || [],
    placeholder: 'Select Company',
    chip: true,
    handleDeleteChip: (value) => handleDeleteCompanyChip(value, uuid)
  });

  const searchSectorProps = (uuid) => ({
    handleChange: (event, value) => handleSectorChange(event, value, uuid),
    options: allSectors,
    selectedValues: files.find((file) => file.uuid === uuid)?.sectorSelectedValues || [],
    placeholder: 'Select Themes',
    chip: true,
    handleDeleteChip: (value) => handleDeleteSectorChip(value, uuid)
  });

  const handleFileTypeChange = (fileId, value) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === fileId) {
        if (value === 'webLinks') {
          handleCheckboxChange(file.uuid, 'webLinks');
        }
        return { ...file, document_type: value };
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const fileTypeOption = [
    {
      key: 'reports',
      value: 'Reports'
    },
    {
      key: 'presentation',
      value: 'Presentation'
    },
    {
      key: 'spreadsheet',
      value: 'Spreadsheet'
    },
    ...(process.env.REACT_APP_DISABLE_WEB_LINKS === 'true'
      ? []
      : [
          {
            key: 'webLinks',
            value: 'Web Links'
          }
        ])
  ];

  return (
    <>
      <LoadingPopover open={isLoading} />
      <ErrorToast errorMessage={errorMessage} />
      <SuccessToast successMessage={successMessage} />
      <Grid
        container
        style={{ height: 'calc(100vh - 240px)', overflowY: 'auto', padding: '5px 4px 0px 0px' }}
      >
        <Grid className={classes.uploadBox} item xs={12} sm={12} md={12} lg={12}>
          <div
            {...getRootProps()}
            style={{
              border: '2px dashed #0276E8',
              borderRadius: '8px',
              padding: '20px',
              textAlign: 'center',
              height: '160px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <input {...getInputProps()} />
            <FileUploadIcon />
            <Typography className={classes.uploadText}>
              Drag & Drop files here or click <span style={{ color: '#004CFF' }}>Select Files</span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {files.length > 0 && (
            <>
              <Grid className={classes.fileGrid} container>
                <Grid item xs={3.3}>
                  <Typography className={classes.uploadHeadderFiles}>
                    {' '}
                    <strong>Files</strong>
                  </Typography>
                </Grid>
                <Grid className={classes.uploadHeadderTags} item xs={8.5}>
                  <strong>Tags</strong>
                </Grid>
                <Grid className={classes.uploadHeadderDelete} item xs={0.2} />
                {files.map((file) => (
                  <>
                    <Grid item xs={3.3} style={{ padding: '0px 0px 5px 0px' }}>
                      <Grid container>
                        <Grid item xs={7.8}>
                          <Typography className={classes.uploadFilesName}>{file.name}</Typography>
                        </Grid>
                        <Grid item xs={4.2} style={{ padding: '0px 2px' }}>
                          <CustomSelect
                            value={file.document_type}
                            options={fileTypeOption}
                            handleChange={(value) => handleFileTypeChange(file.uuid, value)}
                            label={'File Type'}
                            placeholder={'File Type'}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={classes.uploadHeadderTagsMain} item xs={8.5}>
                      <Grid
                        container
                        style={{
                          background: '#F7F7FC',
                          opacity: file.document_type === 'webLinks' ? 0.3 : 1
                        }}
                      >
                        <Grid item xs={4.6}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              size="small"
                              style={{
                                color: '#004CFF',
                                pointerEvents: file.document_type === 'webLinks' ? 'none' : 'auto'
                              }}
                              value={'company'}
                              checked={!!file.association_types?.includes('company')}
                              onChange={() => handleCheckboxChange(file.uuid, 'company')}
                            />
                            <Typography className={classes.uploadCheckBoxTest}>
                              Companies
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: file.association_types.includes('company') ? 'grid' : 'none',
                              backgroundColor: '#FFFFFF'
                            }}
                          >
                            <SearchComp {...searchCompProps(file.uuid)} />
                          </div>
                        </Grid>
                        <Grid item xs={0.15} />
                        <Grid item xs={4.6}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              size="small"
                              style={{
                                color: '#004CFF',
                                pointerEvents: file.document_type === 'webLinks' ? 'none' : 'auto'
                              }}
                              value={'themes'}
                              checked={!!file.association_types?.includes('themes')}
                              onChange={() => handleCheckboxChange(file.uuid, 'themes')}
                            />
                            <Typography className={classes.uploadCheckBoxTest}>Themes</Typography>
                          </div>
                          <div
                            style={{
                              display: file.association_types.includes('themes') ? 'grid' : 'none',
                              backgroundColor: '#FFFFFF'
                            }}
                          >
                            <SearchComp {...searchSectorProps(file.uuid)} />
                          </div>
                        </Grid>
                        <Grid item xs={0.15} />
                        <Grid item xs={2.5}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              size="small"
                              style={{
                                color: '#004CFF',
                                pointerEvents: file.document_type === 'webLinks' ? 'none' : 'auto'
                              }}
                              value={'miscellaneous'}
                              checked={!!file.association_types?.includes('miscellaneous')}
                              onChange={() => handleCheckboxChange(file.uuid, 'miscellaneous')}
                            />
                            <Typography className={classes.uploadCheckBoxTest}>
                              Miscellaneous
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={classes.removeFile} item xs={0.2}>
                      <Tooltip title={'File will be removed from upload'}>
                        <FileRemoveIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleRemoveFile(file.uuid)}
                        />
                      </Tooltip>
                    </Grid>
                  </>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {files.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
          <Button
            className={classes.cancleButton}
            style={{ width: '80px', height: '30px' }}
            onClick={() => {
              setFiles([]);
            }}
          >
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#08070D',
                textTransform: 'none',
                fontFamily: 'Satoshi'
              }}
            >
              Cancel
            </Typography>
          </Button>
          <div style={{ paddingLeft: '10px' }}></div>
          <Button
            className={classes.uploadButton}
            onClick={() => {
              validateFileTags();
            }}
            style={{ width: '80px', height: '30px' }}
          >
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#FFFFFF',
                textTransform: 'none',
                fontFamily: 'Satoshi'
              }}
            >
              Upload
            </Typography>
          </Button>
        </div>
      )}
    </>
  );
};

export default UploadFile;
