import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import CompanyThemeSearch from './companyThemeSearch';
import ReportDropDown from './reportDropDown';
import { getApi } from '../../../../services/token';

const useStyles = makeStyles(() => ({}));

const TearSheetSearchBar = ({ tearSheetSearchBarProps }) => {
  const { location, navigate, updateParamsInURL, searchParams, onSelectReportId, COMPANY_API_URL } =
    tearSheetSearchBarProps;
  const queryParamName = 'search';
  const query_search = searchParams.get(queryParamName);
  const query_report_id = searchParams.get('report_id');

  const [selectedItem, setSelectedItem] = useState(null);

  const [reportData, setReportData] = useState([]);
  const [reportId, setReportId] = useState(query_report_id || '');

  const handleReportChange = (e) => {
    const report_id = e.target.value;
    setReportId(report_id);

    if (report_id) {
      updateParamsInURL({
        navigate,
        location,
        addParams: { report_id: report_id }
      });
    }
  };

  useEffect(() => {
    if (selectedItem && reportId) onSelectReportId(selectedItem, reportId);
  }, [selectedItem, reportId]);

  const getReports = async (selectedCompany) => {
    if (selectedCompany?.entity_id) {
      const reportData = await getApi(
        `${COMPANY_API_URL}/v2/report?entity_type=company&entity_id=${selectedCompany.entity_id}`
      );
      setReportData(reportData.data.data || []);
    }
  };

  useEffect(() => {
    if (!query_report_id) setReportId('');

    if (!(selectedItem && query_search)) {
      setReportData([]);
    }
  }, [query_search, selectedItem, query_report_id]);

  const classes = useStyles();
  console.log(classes);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <CompanyThemeSearch
          {...tearSheetSearchBarProps}
          getReports={getReports}
          COMPANY_API_URL={COMPANY_API_URL}
          queryParamName={queryParamName}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </Grid>
      <Grid item xs={3}>
        <ReportDropDown
          {...tearSheetSearchBarProps}
          reportData={reportData}
          setReportId={setReportId}
          reportId={reportId}
          handleReportChange={handleReportChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
};

export default TearSheetSearchBar;
