import React from 'react';
import { Card, CardMedia, Grid } from '@mui/material';
import './about.css';
import { makeStyles } from '@material-ui/core/styles';
import CustomCard from '../shared/card';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    'justify-content': 'center',
    'text-align': 'center',
    color: '#ffffff',
    'margin-top': '17px!important',
    // background:
    //   'linear-gradient(90deg, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%) 0% 0% no-repeat padding-box padding-box',
    'border-radius': '14px',
    '& h2': {
      'font-weight': 400,
      'font-size': '2rem',
      [theme.breakpoints.up('md')]: {
        'padding-top': '100px',
        'padding-bottom': '100px'
      }
    }
  },
  cardGrid: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    padding: '19px!important',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around'
    }
  },
  fontFamily: {
    'font-family': 'Satoshi!important'
  },
  pdRight: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '32px'
    }
  },
  pdLeft: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '32px'
    }
  },
  aboutusHeading: {
    fontFamily: 'Satoshi',
    fontWeight: 500,
    fontSize: '36px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0px 30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '30px'
    }
  },
  aboutusLineSpacing: {
    padding: '30px 0px 30px 0px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0px 20px 0px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '10px 0px 10px 0px'
    }
  },
  paraStyle: {
    fontFamily: 'Satoshi',
    color: '#171717',
    fontSize: '20px',
    padding: '10px 0px 10px 0px',
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  getToknow: {
    color: '#171717',
    padding: '10px 19px 10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  topContainer: {
    justifySelf: 'center',
    backgroundImage: 'linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%)',
    color: 'white',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  gridText: {
    maxWidth: '1440px',
    margin: '0px auto',
    padding: '0px 120px',
    [theme.breakpoints.down('sm')]: {
      padding: ' 0px 20px'
    }
  }
}));

const Test = () => {
  const classes = useStyles();
  return (
    <>
      <Grid className="gird-aboutus-style" style={{ display: 'grid', padding: '0px' }}>
        <Grid container className={classes.topContainer}>
          <Grid item xs={12}>
            <Typography>
              <span className={classes.aboutusHeading}>
                We help maximize your chances of investing in a unicorn.
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.gridText}>
          <Grid item xs={12} style={{ padding: '19px' }}>
            <div>
              <Typography className={classes.paraStyle}>
                As private markets continue to expand across the globe and provide increasingly
                lucrative prospects, investors need to be better empowered to harness those
                opportunities into their portfolios.
              </Typography>
              <Typography className={classes.paraStyle}>
                Our innovative SaaS platform, PrivateBlok, brings together fragmented and hidden
                private market data silos, both external and internal, and makes it all available to
                you at your command - all you need to do is “Ask” for it!
              </Typography>
              <Typography className={classes.paraStyle}>
                At PrivateBlok, we are amalgamating cutting-edge technologies like Generative AI,
                specialized machine learning models and intricate workflows with trusted public data
                as well as secure private data.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className="gird-aboutus-style"
        style={{ paddingTop: '0px', maxWidth: '1440px', margin: '0px auto' }}
      >
        <Typography
          style={{ fontFamily: 'Satoshi', fontWeight: 600 }}
          className={classes.getToknow}
        >
          Get to know our team
        </Typography>
        <Grid item xs={12} className={classes.cardGrid}>
          <CustomCard
            name={'Dimple Sadhwani'}
            designation={'Co-Founder'}
            imageUrl={'/images/about/team/Dimple.jpeg'}
            text={
              'Dimple Sadhwani is an accomplished machine learning and quantitative finance expert with 25+ years at leading financial institutions like JP Morgan Chase, Walmart Labs, Goldman Sachs, and Nasdaq. She has spearheaded business transformations through scalable analytic products by setting up and leading global teams across risk, compliance, finance, and retail.'
            }
            linkedUrl={'https://www.linkedin.com/in/dimple-sadhwani-b07641b/'}
          />
          <CustomCard
            name={'Sachin Manchanda'}
            designation={'Co-Founder'}
            imageUrl={'/images/about/team/Sachin.jpg'}
            text={
              "Sachin Manchanda has 25+ years of experience in financial markets across portfolio management, risk management, and software development. He has successfully led teams across New York, London, Singapore and Mumbai at global organizations including Morgan Stanley, Moon Capital, Oaktree Capital, GMO, Citi, and Oracle Financials. An IIT-BHU alum, Sachin also holds an MBA degree from the London Business School, and a Master's Degree in Financial Engineering from UC Berkeley’s Haas School of Business."
            }
            linkedUrl={'https://www.linkedin.com/in/sachin-manchanda-72823a/'}
          />
        </Grid>
        <Grid item xs={12} padding="19px">
          <Card>
            <CardMedia component="img" image="/images/about/team/team.png"></CardMedia>
          </Card>
        </Grid>
        <br />
        <br />
        <br />
      </Grid>
    </>
  );
};

export default Test;
