export const SETGRIDCONFIG = 'SETGRIDCONFIG';
export const SETCOLCONFIG = 'SETCOLCONFIG';
export const SETFILTER = 'SETFILTER';
export const SETINPUTFILTER = 'SETINPUTFILTER';
export const SETPREVIOUSFILTER = 'SETPREVIOUSFILTER';
export const SETUUID = 'SETUUID';
export const SETUSERMESSAGE = 'SETUSERMESSAGE';
export const SETRESPMESSAGE = 'SETRESPMESSAGE';
export const SETCOMPANYDETAILS = 'SETCOMPANYDETAILS';
export const SETCOMPANYDASHBOARDDETAILS = 'SETCOMPANYDASHBOARDDETAILS';
export const SETHOMETABS = 'SETHOMETABS';
export const SETCOMPANYNAME = 'SETCOMPANYNAME';
export const SETCOMPANYCIN = 'SETCOMPANYCIN';
export const SETCOMPANYAPICONTEXT = 'SETCOMPANYAPICONTEXT';
export const SETCOMPANYMENUCLICKED = 'SETCOMPANYMENUCLICKED';
export const SETISCOMPANYDATAVISIBLE = 'SETISCOMPANYDATAVISIBLE';
export const SETLOADING = 'SETLOADING';
export const SETLOADINGANSWERSTREAM = 'SETLOADINGANSWERSTREAM';
export const SETSHOWCOMPANYBUTTON = 'SETSHOWCOMPANYBUTTON';
export const SETISCOMPANYDATAPOPULATED = 'SETISCOMPANYDATAPOPULATED';
export const SETALLMESSAGES = 'SETALLMESSAGES';
export const SETCHATSCROLL = 'SETCHATSCROLL';
export const SETCHATSEARCHINPUT = 'SETCHATSEARCHINPUT';
export const SHOWCOMPANYPAGE = 'SHOWCOMPANYPAGE';
export const UPDATEPREVIOUSROUTE = 'UPDATEPREVIOUSROUTE';
export const SETASKBOTWIDTH = 'SETASKBOTWIDTH';
export const SETSTOPRESPONSE = 'SETSTOPRESPONSE';
export const RESETSTATE = 'RESETSTATE';
export const SETREGENERATERESPONSE = 'SETREGENERATERESPONSE';
export const SETQUESTIONTABS = 'SETQUESTIONTABS';
export const SETEXPLOREPAGINATION = 'SETEXPLOREPAGINATION';
export const SETNEWS = 'SETNEWS';
export const SETDASHBOARDNEWS = 'SETDASHBOARDNEWS';
export const SETISFIRSTTIMEUSER = 'SETISFIRSTTIMEUSER';
export const SETISSTEPFINISHED = 'SETISSTEPFINISHED';
export const SETWHITELABELDETAILS = 'SETWHITELABELDETAILS';
export const SETCURRENTSTEP = 'SETCURRENTSTEP';
export const SETSHOWTOURGUIDE = 'SETSHOWTOURGUIDE';
export const ENDTOURGUIDE = 'ENDTOURGUIDE';
export const SETTEARSHEETKEYS = 'SETTEARSHEETKEYS';
export const SETTEARSHEETENTITIESOPTIONS = 'SETTEARSHEETENTITIESOPTIONS';
export const SETTEARSHEETTEMPLATEOPTIONS = 'SETTEARSHEETTEMPLATEOPTIONS';
export const SETTEARSHEETDATA = 'SETTEARSHEETDATA';
export const SETMCADATA = 'SETMCADATA';
export const SETDASHBOARDQUESTION = 'SETDASHBOARDQUESTION';
export const SETCOMPANYLISTDETIALS = 'SETCOMPANYLISTDETIALS';
