import { Close } from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import React from 'react';

const CustomChip = ({ value, label, handleDelete, disabled }) => {
  return (
    <Chip
      label={label}
      style={{
        borderRadius: '2px',
        margin: '7px 3px',
        backgroundColor: '#E8F0FE',
        color: '#004CFF',
        opacity: '1',
        height: 'auto',
        padding: '4px 0px'
      }}
      onDelete={() => handleDelete(value)}
      deleteIcon={<Close />}
      disabled={disabled}
    />
  );
};

export default React.memo(CustomChip);
