// import { LinearProgress } from '@mui/material';
import { useState } from 'react';
// import { getApi, postApi, putApi } from '../../../services/token';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import TearSheetSearchBar from './tearSheetSearchBar';
import { updateParamsInURL } from '../../../helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import TearSheetMainPageV1 from '../tearSheet-V1/tearSheetMainPage';
import { getApi } from '../../../services/token';
import TearSheet from './report/tearSheet';

const isTearSheetEnabled = process.env.REACT_APP_ENABLE_TEARSHEET === 'true';
const COMPANY_API_URL = process.env.REACT_APP_COMPANY_API;

const useStyles = makeStyles((theme) => ({
  // mainDiv: {
  //   minHeight: 'calc(100vh - 120px)',
  //   backgroundColor: '#F7F9FD'
  // },
  tearSheetDiv: {
    padding: '0px 20px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 15px 15px'
    }
  },
  maxWidthDiv: {
    maxWidth: '1440px',
    margin: '0px auto',
    position: 'relative'
  },
  '@media print': {
    tearSheetDiv: {
      visibility: 'visible',
      position: 'absolute',
      left: 0,
      top: '-10px'
    }
    // mainDiv: {
    //   visibility: 'hidden'
    // }
  },
  mainDiv: {
    padding: '10px 120px',
    gap: '56px',
    background: '#F7F9FD'
  },
  reportDataCls: {
    padding: '10px 120px'
  },
  minHeight: {
    minHeight: 'calc(100vh - 120px)'
  },
  fontCFamilyStyle: {
    fontFamily: 'Satoshi',
    fontStyle: 'normal'
  },
  customText: {
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '30px',
    margin: 0
  },
  gradientText: {
    'background-clip': ' text !important',
    'background-image':
      'linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%) !important',
    '-webkit-text-fill-color': 'transparent !important'
  },
  wt100: {
    width: '100%'
  },
  pointer: {
    cursor: 'pointer !important'
  },
  toggleButton: {
    fontSize: '16px!important',
    'font-weight': 700,
    'line-height': '24px!important',
    'letter-spacing': '0.5px!important',
    '&.Mui-selected': {
      background:
        'transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box',
      color: 'white!important'
    },
    '&.MuiToggleButton-root': {
      border: '1px solid var(--Border-Colors-Border3, #E7E7E7)!important',
      textTransform: 'none',
      padding: '6px 16px',
      '&:not(:last-child)': {
        marginRight: '20px'
      },
      'border-radius': '4px !important',
      gap: '20px'
    }
  },
  mBtPdTop: {
    marginBottom: '20px',
    'padding-top': '10px'
  }
}));

const TearSheetMainPage = ({ currentRoute }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const entity_type = searchParams.get('entity_type');
  const { t } = useTranslation();

  const [selectedFilter, setSelectedFilter] = useState(entity_type || 'company');
  const [reportSummary, setReportSummary] = useState(null);

  const handleFilterChange = (event, newFilter) => {
    if (newFilter) {
      setSelectedFilter(newFilter);
      updateParamsInURL({
        navigate,
        location,
        addParams: { entity_type: newFilter },
        isNew: true
      });
    }
  };

  const getSummaryData = async (selectedEntity, report_id) => {
    // report_id = '65a1703832651d4e0e1ca499';
    if (report_id && selectedFilter && selectedEntity?.entity_id) {
      const { data } = await getApi(
        `${COMPANY_API_URL}/v1/company_tear_sheet?entity_id=${selectedEntity?.entity_id}&entity_type=${selectedFilter}&template_id=${report_id}`
      );
      setReportSummary(data?.data);
    }
  };

  const onSelectReportId = (selectedEntity, report_id) => {
    getSummaryData(selectedEntity, report_id);
  };

  const tearSheetSearchBarProps = {
    t,
    selectedFilter,
    location,
    navigate,
    updateParamsInURL,
    searchParams,
    onSelectReportId,
    COMPANY_API_URL
  };

  return (
    <>
      {isTearSheetEnabled ? (
        <>
          <div className={classes.minHeight}>
            <div className={classes.mainDiv}>
              <p
                className={`${classes.wt100} ${classes.customText}
               ${classes.fontCFamilyStyle}
               `}
              >
                <span className={classes.gradientText}>Custom Reports</span>
              </p>
              <div className={classes.mBtPdTop}>
                <ToggleButtonGroup
                  value={selectedFilter}
                  exclusive
                  onChange={handleFilterChange}
                  className={classes.pointer}
                >
                  <ToggleButton value="company" className={classes.toggleButton}>
                    Company
                  </ToggleButton>
                  <ToggleButton value="theme" className={classes.toggleButton}>
                    Theme
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

              <TearSheetSearchBar tearSheetSearchBarProps={tearSheetSearchBarProps} />
            </div>
            <div className={`${classes.tearSheetDiv} ${classes.reportDataCls}`}>
              {reportSummary && <TearSheet tearSheetData={reportSummary} />}
            </div>
          </div>
        </>
      ) : (
        <TearSheetMainPageV1 currentRoute={currentRoute} />
      )}
    </>
  );
};

export default TearSheetMainPage;
