import { selecteEntitesFilterToDisplayMap } from '../../../../constants/tearSheet/tearSheet';
import { MenuItem, makeStyles } from '@material-ui/core';
import { FormControl, InputLabel, Select } from '@mui/material';
import EntityTypeFilterTabs from '../../../atoms/entitySearch/entityTypeFilterTabs';

const useStyles = makeStyles((theme) => ({
  templateSelectForm: {
    width: '305px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px !important',
      width: '280px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '10px !important'
    }
  },
  inputLabel: {
    fontFamily: 'Satoshi !important',
    color: '#000000 !important',
    opacity: 0.6
  },
  select: {
    backgroundColor: '#FFFFFF',
    height: '47px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& fieldset': {
      borderWidth: '1px !important',
      borderRadius: '3px',
      borderColor: '#E3EBFD !important'
    },
    '& ul.css-6hp17o-MuiList-root-MuiMenu-list': {
      padding: '0px'
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Satoshi !important'
    }
  },
  publicDataText: {
    fontSize: '14px',
    margin: '0px 0px 1px 8px',
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  }
}));

const ReportDropDown = ({ t, reportData, reportId, handleReportChange }) => {
  //, report_data, reportId, handleReportChange
  const classes = useStyles();

  return (
    <>
      <FormControl
        className={classes.templateSelectForm}
        // style={currentPage === 'template' ? { margin: '0px !important' } : {}}
      >
        <InputLabel className={classes.inputLabel}>{t('tearSheet.templateSearchLabel')}</InputLabel>
        <Select
          label="template"
          className={classes.select}
          value={reportId || ''}
          onChange={handleReportChange}
          style={{ fontFamily: 'Satoshi' }}
        >
          <EntityTypeFilterTabs
            tabsOptions={selecteEntitesFilterToDisplayMap}
            setFilterTab={() => {}}
            selectedTab={''}
          />
          {reportData &&
            reportData.map((report, index) => (
              <MenuItem key={index} value={report.report_id}>
                <span>{report.report_name}</span>
                {/* {report.data_used === 'pb_data' && (
                                <span className={classes.publicDataText}>{t('tearSheet.publicDataText')}</span>
                            )} */}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default ReportDropDown;
