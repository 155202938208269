import { useState, useEffect } from 'react';
import { getApi, postApi } from '../../services/token';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import DocLibList from './manage/list';
import UploadFile from './upload';
import { useNavigate } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Grid, Button } from '@mui/material';
import { ReactComponent as RefreshIcon } from '../../components/icons/RefreshIcon.svg';

const companyUrl = process.env.REACT_APP_COMPANY_API;

const useStyles = makeStyles((theme) => ({
  logo: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    opacity: 1
  },
  menuHeading: {
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  headingWt: {
    fontSize: '26px',
    padding: '17px 10px 17px 10px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      paddingLeft: '2px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  listItem: {
    padding: '0px 36px 0px 36px',
    height: '58px',
    display: 'flex',
    alignItems: 'center'
  },
  docHeading: {
    fontFamily: 'Satoshi',
    fontSize: '22px',
    fontWeight: '400',
    textAlign: 'left',
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  fadeIn: {
    opacity: 1
  },
  fadeOut: {
    opacity: 0,
    transition: 'opacity 0.1s ease-out'
  },
  borderButtonStyle: {
    marginLeft: 'auto',
    position: 'relative',
    height: '38px',
    width: '100px',
    zIndex: 1,
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    textTransform: 'none !important',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1.5px',
      right: '1.5px',
      top: '1.5px',
      bottom: '1.5px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  textBtn: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    color: '#171717 !important',
    padding: '0px 10px'
  }
}));

const DocLib = ({ isupload }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [allCompanies, setAllCompanies] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [allSectors, setAllSectors] = useState([]);

  // const currentSelection = isupload ? 0 : 1;

  const navigate = useNavigate();

  const currentSelection = isupload ? 1 : 0;

  const handleTabChange = (newValue) => {
    navigate(`/document-upload/${newValue === 'Upload' ? 'manage' : 'upload'}`);
  };

  const handleRefresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const getCompanyList = async () => {
    try {
      const companyListData = await postApi(
        `${companyUrl}/v1/company_tear_sheet/template_companies`,
        {}
      );
      if (companyListData && companyListData.data) {
        const parsedData = companyListData.data.data.map((comp) => ({
          key: comp.cin,
          value: comp.name
        }));
        setAllCompanies(parsedData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSectorList = async () => {
    const sectorListData = await getApi(`${companyUrl}/v1/entity/search?entity_types=theme`);
    if (sectorListData && sectorListData.data) {
      const parsedData = sectorListData.data.data.items.map((comp) => ({
        key: comp.entity_id,
        value: comp.entity_name
      }));
      setAllSectors(parsedData);
    }
  };

  useEffect(() => {
    getCompanyList();
    getSectorList();
  }, []);

  if (process.env.REACT_APP_ENABLE_DOCUMENT_UPLOAD !== 'true') {
    useEffect(() => {
      navigate('/home');
    }, []);

    return <></>;
  }

  return (
    <>
      <Grid
        container
        style={{
          background: '#EFF3FC',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            maxWidth: '1440px',
            width: '100%'
          }}
        >
          <div className={classes.listItem}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div>
                <Typography className={classes.docHeading}>
                  Documents Library - {currentSelection === 0 ? 'Manage' : 'Upload'}
                </Typography>
              </div>
              <div>
                {currentSelection === 0 ? (
                  <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: '24px' }}>
                      <RefreshIcon
                        style={{
                          cursor: 'pointer',
                          border: '1px solid #797F8D',
                          borderRadius: '4px',
                          padding: '6.25px 7.5px',
                          height: '21.5px',
                          background: '#FFFFFF'
                        }}
                        onClick={() => {
                          handleRefresh();
                        }}
                      />
                    </div>
                    <Button
                      className={classes.borderButtonStyle}
                      onClick={() => handleTabChange('Manage')}
                    >
                      <Typography className={classes.textBtn}>
                        {t('sideBar.compInfoUploadH3')}
                      </Typography>
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      className={classes.borderButtonStyle}
                      onClick={() => handleTabChange('Upload')}
                    >
                      <Typography className={classes.textBtn}>
                        {t('sideBar.docLibUpdateH3')}
                      </Typography>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        container
        style={{
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            textAlign: 'left',
            maxWidth: '1440px',
            width: '100%',
            minHeight: 'calc(100vh - 178px)'
          }}
        >
          <div
            style={{
              padding: '16px 36px 10px 36px'
            }}
          >
            {currentSelection === 1 && (
              <UploadFile
                propsData={{
                  allCompanies,
                  allSectors
                }}
              />
            )}
            {currentSelection === 0 && (
              <DocLibList
                propsData={{
                  allCompanies,
                  allSectors
                }}
                refreshFlag={refreshFlag}
              />
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

export default DocLib;
