import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Paper,
  MenuList,
  MenuItem,
  ListItemIcon,
  Divider,
  Button
} from '@mui/material';
import { ReactComponent as LiveTrackerIcon } from '../../components/icons/LiveTrackerIcon.svg';
import { ReactComponent as TearSheetsIcon } from '../../components/icons/TearSheetsIcon.svg';
import { ReactComponent as AskByzIcon } from '../../components/icons/AskByzIcon.svg';
import { ReactComponent as DocIngestIcon } from '../../components/icons/DocIngestIcon.svg';
import { ReactComponent as DocIngestSelectedIcon } from '../../components/icons/DocIngestSelectedIcon.svg';
import { ReactComponent as AskByzSelectedIcon } from '../../components/icons/AskByzSelectedIcon.svg';
import { ReactComponent as TearSheetsSelectedIcon } from '../../components/icons/TearSheetsSelectedIcon.svg';
import { ReactComponent as LiveTrackerSelectedIcon } from '../../components/icons/LiveTrackerSelectedIcon.svg';
import { ReactComponent as MyWatchlistSelectedIcon } from '../../components/icons/MyWatchlistSelectedIcon.svg';
import { ReactComponent as MyWatchlist } from '../../components/icons/MyWatchlist.svg';

import AskByzHelpCenter from './helpCenterContent/askByzHelpCenter';
import TearSheetsHelpCenter from './helpCenterContent/tearSheetsHelpCenter';
import DocIngestHelpCenter from './helpCenterContent/docIngestHelpCenter';
import LiveTrackerHelpCenter from './helpCenterContent/liveTrackerHelpCenter';
import MyWatchlistHelpCenter from './helpCenterContent/myWatchlistHelpCenter';

import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsStepFinished, endTourGuide } from '../shared/action';
import { useNavigate } from 'react-router-dom';
import { setCurrentStepNumber, setShowTourGuide } from '../shared/action';

const useStyles = makeStyles({
  helpCenterGrid: {
    padding: '10px 40px',
    minHeight: 'calc(100vh - 119.5px)',
    maxWidth: '1440px !important'
  },
  helpCenterMainTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '22px !important',
    fontWeight: '400 !important',
    color: '#373737 !important'
  },
  helpCenterMainGrid: {
    background: '#F7F9FD',
    display: 'flex',
    justifyContent: 'center'
  },
  helpCenterContentGrid: {
    display: 'flex'
  },
  borderStyle: {
    marginLeft: 'auto',
    position: 'relative',
    zIndex: 1,
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    textTransform: 'none',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1.5px',
      right: '1.5px',
      top: '1.5px',
      bottom: '1.5px',
      borderRadius: '12px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  borderButtonStyle: {
    marginLeft: 'auto',
    position: 'relative',
    height: '30px',
    zIndex: 1,
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    textTransform: 'none !important',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1.5px',
      right: '1.5px',
      top: '1.5px',
      bottom: '1.5px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  dividerStyle: {
    backgroundColor: '#FFFFFF',
    height: '5px',
    padding: '0px 16px'
  },
  listItemTextStyle: {
    fontFamily: 'Satoshi !important',
    fontSize: '18px !important',
    color: '#373737 !important'
  },
  listItemTextSelected: {
    fontFamily: 'Satoshi !important',
    fontSize: '18px !important',
    fontWeight: '700 !important',
    color: '#373737 !important'
  },
  tourGuide: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    color: '#171717 !important',
    padding: '0px 10px'
  }
});

function HelpCenter() {
  const classes = useStyles();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [selectedMenuItem, setSelectedMenuItem] = useState('AskByz');
  const navigate = useNavigate();
  const storeCurrentStep = useSelector((state) => state.tokenReducer.currentStep);
  const storeShowTourGuide = useSelector((state) => state.tokenReducer.showTourGuide);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'AskByz':
        return <AskByzHelpCenter />;
      case 'TearSheets':
        return <TearSheetsHelpCenter />;
      case 'DocIngest':
        return <DocIngestHelpCenter />;
      case 'LiveTracker':
        return <LiveTrackerHelpCenter />;
      case 'WatchList':
        return <MyWatchlistHelpCenter />;
      default:
        return <div>Select a menu item to view content</div>;
    }
  };

  const handleClick = () => {
    dispatch(setCurrentStepNumber(1));
    dispatch(setShowTourGuide(true));
  };

  useEffect(() => {
    if (storeCurrentStep === 1 && storeShowTourGuide) {
      navigate('/home');
    }
  }, [storeCurrentStep, storeShowTourGuide, navigate]);

  useEffect(() => {
    if (state?.isStepFinished) {
      dispatch(setIsStepFinished(true));
      dispatch(endTourGuide());
    }
  }, [state?.isStepFinished]);

  return (
    <Grid container className={classes.helpCenterMainGrid}>
      <Grid lg={12} justifyContent="center" className={classes.helpCenterGrid}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '10px'
          }}
        >
          <Typography className={classes.helpCenterMainTxt}>
            <span
              style={{
                backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Help Center
            </span>
          </Typography>
          <Button className={classes.borderButtonStyle} onClick={handleClick}>
            <Typography className={classes.tourGuide}>Quick Tour Guide</Typography>
          </Button>
        </div>
        <Grid className={classes.helpCenterContentGrid}>
          <Grid>
            <Paper
              sx={{
                width: 300,
                boxShadow: 'none',
                borderRadius: '13px'
              }}
              className={classes.borderStyle}
            >
              <MenuList style={{ padding: '2px' }}>
                <MenuItem
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderTopRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    height: '64px'
                  }}
                  onClick={() => handleMenuItemClick('AskByz')}
                  disableRipple
                >
                  <ListItemIcon>
                    {selectedMenuItem === 'AskByz' ? <AskByzSelectedIcon /> : <AskByzIcon />}
                  </ListItemIcon>
                  <Typography
                    className={
                      selectedMenuItem === 'AskByz'
                        ? classes.listItemTextSelected
                        : classes.listItemTextStyle
                    }
                  >
                    Ask Byz Q&A
                  </Typography>
                </MenuItem>
                <div className={classes.dividerStyle}>
                  <Divider />
                </div>
                <MenuItem
                  style={{ backgroundColor: '#FFFFFF', height: '64px' }}
                  onClick={() => handleMenuItemClick('TearSheets')}
                  disableRipple
                >
                  <ListItemIcon>
                    {selectedMenuItem === 'TearSheets' ? (
                      <TearSheetsSelectedIcon />
                    ) : (
                      <TearSheetsIcon />
                    )}
                  </ListItemIcon>
                  <Typography
                    className={
                      selectedMenuItem === 'TearSheets'
                        ? classes.listItemTextSelected
                        : classes.listItemTextStyle
                    }
                  >
                    Tear Sheet
                  </Typography>
                </MenuItem>
                <div className={classes.dividerStyle}>
                  <Divider />
                </div>
                <MenuItem
                  style={{ backgroundColor: '#FFFFFF', height: '64px' }}
                  onClick={() => handleMenuItemClick('DocIngest')}
                  disableRipple
                >
                  <ListItemIcon>
                    {selectedMenuItem === 'DocIngest' ? (
                      <DocIngestSelectedIcon />
                    ) : (
                      <DocIngestIcon />
                    )}
                  </ListItemIcon>
                  <Typography
                    className={
                      selectedMenuItem === 'DocIngest'
                        ? classes.listItemTextSelected
                        : classes.listItemTextStyle
                    }
                  >
                    Document Library
                  </Typography>
                </MenuItem>
                <div className={classes.dividerStyle}>
                  <Divider />
                </div>
                <MenuItem
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderBottomRightRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    height: '64px'
                  }}
                  onClick={() => handleMenuItemClick('WatchList')}
                  disableRipple
                >
                  <ListItemIcon>
                    {selectedMenuItem === 'WatchList' ? (
                      <MyWatchlistSelectedIcon />
                    ) : (
                      <MyWatchlist />
                    )}
                  </ListItemIcon>
                  <Typography
                    className={
                      selectedMenuItem === 'WatchList'
                        ? classes.listItemTextSelected
                        : classes.listItemTextStyle
                    }
                  >
                    My Watchlist
                  </Typography>
                </MenuItem>
                <div className={classes.dividerStyle}>
                  <Divider />
                </div>
                <MenuItem
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderBottomRightRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    height: '64px'
                  }}
                  onClick={() => handleMenuItemClick('LiveTracker')}
                  disableRipple
                >
                  <ListItemIcon>
                    {selectedMenuItem === 'LiveTracker' ? (
                      <LiveTrackerSelectedIcon />
                    ) : (
                      <LiveTrackerIcon />
                    )}
                  </ListItemIcon>
                  <Typography
                    className={
                      selectedMenuItem === 'LiveTracker'
                        ? classes.listItemTextSelected
                        : classes.listItemTextStyle
                    }
                  >
                    Live Tracker
                  </Typography>
                </MenuItem>
              </MenuList>
            </Paper>
          </Grid>
          <Grid style={{ width: '34px' }} />
          <Grid
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: '12px',
              width: '100%',
              boxShadow: '0px 0px 12px 0px rgba(6, 10, 84, 0.08'
            }}
          >
            <div style={{ padding: '20px' }}>{renderContent()}</div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HelpCenter;
