import { Button, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    textTransform: 'none',
    padding: '6px 8px'
  },
  startIcon: {
    marginRight: '-4px',
    fontSize: '17px !important',
    color: '#004CFF'
  },
  typo: {
    fontSize: '14px',
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textDecoration: 'none'
  }
}));

const TertiaryButton = ({ handleClick, StartIcon, btnText }) => {
  const classes = useStyles();
  return (
    <Button
      type="submit"
      className={classes.root}
      onClick={handleClick}
      startIcon={StartIcon ? <StartIcon className={classes.startIcon} /> : null}
    >
      <Typography className={classes.typo}>{btnText}</Typography>
    </Button>
  );
};

export default TertiaryButton;
