import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import { Typography, makeStyles } from '@material-ui/core';
import { Close } from '@mui/icons-material';
// import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  mainDiv: {
    borderRadius: '16px !important',
    height: '566px',
    width: '500px'
  },
  autocompleteInput: {
    height: '4px !important'
  },
  closeBtn: {
    position: 'absolute !important',
    right: '10px',
    top: '8px'
  },
  saveBtn: {
    background: 'linear-gradient(90deg,#004CFF,#06BCC1)'
  }
}));

const CustomDialog = ({
  title = '',
  open = false,
  save = () => {},
  handleClose = () => {},
  Content = <></>
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} classes={{ paper: classes.mainDiv }}>
      <IconButton
        className={classes.closeBtn}
        onClick={() => {
          handleClose();
        }}
      >
        <Close />
      </IconButton>
      <DialogTitle style={{ margin: '20px 0px 0px' }}>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Content />
      </DialogContent>
      <DialogActions style={{ display: 'flex', flexDirection: 'column', padding: '0px 30px 16px' }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.saveBtn}
          onClick={save}
          style={{ textTransform: 'none', fontFamily: 'Satoshi', fontSize: '16px' }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
