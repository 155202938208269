const getNameFunc = async (auth) => {
  const session = await auth.getRefreshedSession();
  let nameVal = session?.idToken?.payload.name;
  nameVal = nameVal.charAt(0).toUpperCase() + nameVal.slice(1);
  const emailVal = session?.idToken?.payload.email;
  const isemailVerified = session?.idToken?.payload.email_verified;
  const nameArr = nameVal.split(' ');
  let nameCharArr = [];

  for (let i = 0; i < nameArr.length; i++) {
    const name = nameArr[i];
    if (i === 0 || i === nameArr.length - 1) {
      if (name[0] !== undefined) {
        nameCharArr.push(name[0].toUpperCase());
      }
    }
  }

  const nameCharVar = nameCharArr.join(' ');

  return { nameVal, nameCharVar, emailVal, isemailVerified };
};

const processNameFunc = (session) => {
  let nameVal = session?.data.data.name;
  nameVal = nameVal.charAt(0).toUpperCase() + nameVal.slice(1);
  const emailVal = session?.data.data.email;
  const isemailVerified = session?.data.data.email_verified;
  const org = session?.data.data.organization;
  const dept = session?.data.data.department;
  const nameArr = nameVal.split(' ');
  let nameCharArr = [];

  for (let i = 0; i < nameArr.length; i++) {
    const name = nameArr[i];
    if (i === 0 || i === nameArr.length - 1) {
      if (name[0] !== undefined) {
        nameCharArr.push(name[0].toUpperCase());
      }
    }
  }

  const nameCharVar = nameCharArr.join(' ');

  return { nameVal, nameCharVar, emailVal, isemailVerified, org, dept };
};

export { getNameFunc, processNameFunc };
