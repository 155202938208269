import { Box, Divider } from '@mui/material';
import { Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainBox: {
    padding: '4px 14px 4px 14px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  tabBox: {
    borderRadius: '4px',
    display: 'flex',
    padding: '2px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E4EBFC',
    background: '#FFF',
    marginRight: '6px',
    minWidth: '10px',
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      '& p': {
        color: 'rgba(23, 23, 23, 0.30) !important'
      }
    }
  },
  selectedTabBox: {
    background: '#004CFF !important'
  },
  tabTypo: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 600,
    color: '#171717'
  },
  selectedTabTypo: {
    color: '#ffffff'
  },
  breakLine: {
    background: '#EDEDED',
    height: '1px',
    marginTop: '8px !important'
  },
  pd2: {
    padding: '2px'
  }
}));

const EntityTypeFilterTabs = ({ props = {}, tabsOptions, setFilterTab, selectedTab }) => {
  const classes = useStyles();
  const handleTabChange = (key) => {
    setFilterTab(key);
  };

  return (
    <div {...props}>
      {tabsOptions && tabsOptions.length > 0 ? (
        <>
          <Box className={classes.mainBox}>
            {tabsOptions.map((option) => (
              <>
                {option.isText ? (
                  <Typography className={`${classes.tabTypo} ${classes.pd2}`}>
                    {option.value}
                  </Typography>
                ) : (
                  <Button
                    variant="outlined"
                    className={`${classes.tabBox} ${
                      selectedTab === option.key && classes.selectedTabBox
                    }`}
                    key={option.key}
                    onClick={() => handleTabChange(option?.key)}
                    disabled={option.disabled}
                  >
                    <Typography
                      className={`${classes.tabTypo} ${
                        selectedTab === option.key && classes.selectedTabTypo
                      }`}
                    >
                      {option.value}
                    </Typography>
                  </Button>
                )}
              </>
            ))}
          </Box>
          <Divider className={classes.breakLine} />
          {props.children && props.children}
        </>
      ) : (
        <>{props.children && props.children}</>
      )}
    </div>
  );
};

export default EntityTypeFilterTabs;
