import React, { useState, useRef, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Dialog, DialogContent } from '@material-ui/core';
import { Button, Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { ReactComponent as FilterIcon } from '../../../components/icons/FilterIcon.svg';
import { ReactComponent as SeeMore } from '../../../components/icons/SeeMore.svg';
import ExternalFilter from './externalFilters/externalFilters';
import ExternalFiltersDesktop from './externalFiltersDesktop/externalFiltersDesktop';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { setCompanyApiContext, setExplorePagination, setInputFilter } from '../action';
import { useDispatch, useSelector } from 'react-redux';
import AppeliedFilters from './appeliedFilters/appeliedFilters';
import { setPreviousFilter, setFilter } from '../action';
import { Skeleton } from '@mui/material';
import { postApi, getApi } from '../../../services/token';
import { isShowCompanyPage } from '../action';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import RequestCompanyPopover from './requestCompanyPopover';
import CreditWarning from '../creditWarning';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#E3EBFD',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 1
    },
    '& .MuiInputBase-input': {
      height: '1px !important'
    },
    width: '400px'
  },
  searchIcon: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: '#80a6ff'
  },
  exploreCompGrid: {
    paddingLeft: '50px',
    paddingRight: '50px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },
  extfilterPadding: {
    paddingLeft: '57.5px',
    paddingRight: '57.5px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '17.5px',
      paddingRight: '17.5px'
    }
  },
  successMessageGrid: {
    paddingLeft: '57.5px',
    paddingRight: '57.5px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '17.5px',
      paddingRight: '17.5px'
    }
  },
  successMessageStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  requestCompanyTextStyle: {
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  paginationGrid: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '15px'
  }
}));

const ExploreCompanies = ({ getUserRemainingCredits }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const companyUrl = process.env.REACT_APP_COMPANY_API;
  const [companyListData, setCompanyListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayPopoverSubmitCopany, setDisplayPopoverSubmitCopany] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [credit, setCredit] = useState(null);
  const checkCredit = false;
  const [loadCreditChanges, setLoadCreditChanges] = useState(false);
  const initialRender = useRef(false);
  const [totalPages, setTotalPages] = useState(0);
  const pageLimit = parseInt(process.env.REACT_APP_EXPLORE_COMPANIES_PAGE_SIZE, 10);
  let emptyArr = new Array(pageLimit).fill(null);
  useEffect(() => {
    if (credit === 0 && checkCredit) {
      loadCreditChanges ? setOpenDialog(true) : setLoadCreditChanges(true);
    }
  }, [credit]);

  const successMessageUploadCompany = false;
  const getFilterData = async () => {
    const filterArray = [
      { field: 'names', fieldName: 'Company' },
      { field: 'focus_area', fieldName: 'Focus Area' },
      { field: 'theme', fieldName: 'Theme' },
      { field: 'country', fieldName: 'Country' },
      { field: 'company_stage', fieldName: 'Company Stage' }
    ];
    const promises = filterArray.map(async (c) => {
      let result;
      let faThemeData = [];
      if (c.field == 'names') {
        const companyListData = await postApi(
          `${companyUrl}/v1/company_tear_sheet/template_companies`,
          {}
        );
        if (companyListData && companyListData.data) {
          const parsedData = companyListData.data.data.map((comp) => ({
            key: comp.cin,
            value: comp.name
          }));
          result = parsedData;
        }
      } else if (c.field == 'focus_area' || c.field == 'theme') {
        if (!faThemeData?.length) {
          const param = encodeURIComponent('focus_area,theme');
          const response = await getApi(`${companyUrl}/v1/fts?entity_types=${param}`);
          faThemeData = response?.data?.data || [];
        }

        const filteredData = faThemeData.filter((x) => x.entity_type === c.field);
        const resultData = filteredData.map((x) => ({
          ...x,
          key: x.entity_id,
          value: x.name
        }));

        return { field: c.field, result: resultData, fieldName: c.fieldName };
      } else {
        const response = await getApi(`${companyUrl}/v1/company/attribute/${c.field}`);
        result = await response.data.data[0].values;
      }
      return { field: c.field, result, fieldName: c.fieldName };
    });
    const results = await Promise.all(promises);
    dispatch(setInputFilter(results));
  };

  const getFilteredCompanyData = async () => {
    try {
      let body = {};
      if (filterRedux.length !== 0) {
        body = {
          filters: {}
        };
        body.filters = {};
        for (const filter of filterRedux) {
          body.filters[filter.attribute] = filter.values.map((obj) =>
            obj.entity_type === 'focus_area' || obj.entity_type === 'theme'
              ? obj.entity_id
              : obj.value
          );
        }
      }
      body.page_limit = pageLimit;
      body.page_number = explorePagination.pageNumber;
      let response = null;
      let updatedCompanyListData = [...companyListData];
      if (explorePagination.filtersChanged) {
        response = await postApi(`${companyUrl}/v1/companies`, body);
        const totalCount = response.data.data.total_count;
        let updatedTotalPages = Math.floor((totalCount - 1) / pageLimit) + 1;
        updatedCompanyListData = [];
        if (updatedTotalPages) {
          updatedCompanyListData = new Array(updatedTotalPages - 1).fill(emptyArr);
          updatedCompanyListData.push(
            new Array(totalCount - (updatedTotalPages - 1) * pageLimit).fill(null)
          );
        }
        setTotalPages(updatedTotalPages);
      } else if (!companyListData[explorePagination.pageNumber - 1][0]) {
        response = await postApi(`${companyUrl}/v1/companies`, body);
      }
      if (response && response.data) {
        const parsedData = response.data.data.company_list;
        updatedCompanyListData[explorePagination.pageNumber - 1] = parsedData;
        setCompanyListData([...updatedCompanyListData]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getRemainingCredits = async () => {
    const userPlanData = await getUserRemainingCredits();
    if (userPlanData) {
      const { available_question_limit } = userPlanData;
      setCredit(available_question_limit ? available_question_limit : 0);
    }
  };
  useEffect(() => {
    getFilterData();
    getRemainingCredits();
  }, []);

  const dispatch = useDispatch();
  const filterRedux = useSelector((state) => state.tokenReducer.filter);
  const filterPreviousRedux = useSelector((state) => state.tokenReducer.previousFilter);
  const explorePagination = useSelector((state) => state.tokenReducer.explorePagination);

  useEffect(() => {
    let updatedExplorePagination = { ...explorePagination };
    if (!initialRender.current) {
      updatedExplorePagination.pageNumber = explorePagination.pageNumber
        ? explorePagination.pageNumber
        : 1;
    } else {
      updatedExplorePagination.pageNumber = 1;
    }
    updatedExplorePagination.filtersChanged = true;
    dispatch(setExplorePagination(updatedExplorePagination));
  }, [filterRedux]);

  useEffect(() => {
    const fetchData = async () => {
      if (initialRender.current) {
        setLoading(true);
        await getFilteredCompanyData();
        setLoading(false);
      } else {
        initialRender.current = true;
      }
    };
    fetchData();
  }, [explorePagination]);

  const handlePageChange = (e, pageNum) => {
    let updatedExplorePagination = { ...explorePagination };
    updatedExplorePagination.pageNumber = pageNum;
    updatedExplorePagination.filtersChanged = false;
    dispatch(setExplorePagination(updatedExplorePagination));
  };
  const [displayFilterPopover, setDisplayFilterPopover] = useState(false);
  const handleClickOpen = () => {
    setDisplayFilterPopover(true);
  };

  const [displayedCompanies, setDisplayedCompanies] = useState(25);
  const scrollRef = useRef(null);

  const handleShowMore = () => {
    setDisplayedCompanies(displayedCompanies + 25);
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const tokenName = 'token_name';
  const handleClearAll = () => {
    setDisplayFilterPopover(false);
    let clearExtFilter = filterRedux.filter((c) => c.attribute === tokenName);
    let checkExtFilterData = filterRedux.filter((c) => c.attribute !== tokenName);
    if (checkExtFilterData.length === 0) {
      dispatch(setPreviousFilter([]));
      return;
    }
    dispatch(setFilter(clearExtFilter));
    dispatch(setPreviousFilter([]));
  };

  const handleApplyFilter = () => {
    setDisplayFilterPopover(false);
    dispatch(setFilter(filterPreviousRedux));
  };

  const handleCloseOpen = () => {
    setDisplayFilterPopover(false);
    dispatch(setPreviousFilter(filterRedux));
  };

  const handleCompanyClick = (cin, name) => {
    if (credit === 0 && checkCredit) {
      setOpenDialog(true);
      return;
    }

    if (matches) dispatch(isShowCompanyPage('/explore-all-companies'));

    const body = {
      cin: cin,
      name: name
    };
    dispatch(setCompanyApiContext(body));
    navigate('/askbyz');
  };
  const exploreHeight = matches ? 'calc(100vh - 174px)' : 'calc(100vh - 294.5px)';

  return (
    <>
      <CreditWarning openDialog={openDialog} setOpenDialog={setOpenDialog} />
      {matches ? <div style={{ height: '20px' }} /> : null}
      <Grid
        container
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: '24px',
          maxWidth: '1440px',
          margin: '0 auto'
        }}
      >
        <Grid
          item
          xs={12}
          className={classes.successMessageGrid}
          style={{
            height: '40px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {matches ? (
            <div>
              <div style={{ height: '10px' }}></div>
              <Button
                style={{
                  textTransform: 'none',
                  border: '2px solid #E4EBFC',
                  fontFamily: 'Satoshi',
                  fontSize: '14px',
                  fontWeight: 'normal',
                  borderRadius: '3px',
                  padding: '2px 14px'
                }}
                className={classes.button}
                onClick={handleClickOpen}
              >
                <>
                  <FilterIcon style={{ marginRight: '5px' }} />
                  {/* {t('gridHeadder.filter')} */}
                  <span
                    style={{
                      backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontWeight: '600',
                      color: '#08070D'
                    }}
                    className={classes.requestCompanyTextStyle}
                  >
                    {t('gridHeadder.filter')}
                  </span>
                </>
              </Button>
            </div>
          ) : null}
          <div className={classes.successMessageStyle}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              {successMessageUploadCompany ? (
                <div style={{ display: 'flex' }}>
                  <Box
                    style={{
                      background: '#C7F1BF',
                      borderRadius: '5px',
                      opacity: 1,
                      width: '240px',
                      height: '30px',
                      display: 'flex', // Set display to flex
                      alignItems: 'center' // Vertically center align the content
                    }}
                  >
                    <CheckCircleOutlineIcon
                      style={{
                        marginLeft: '13px', // Set margin left to 13px
                        marginRight: '2px', // Set margin right to 1px
                        color: '#3C962A' // Set color of the icon to #3C962A
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#206113',
                        opacity: 1,
                        fontFamily: 'Satoshi',
                        fontWeight: 'normal'
                      }}
                    >
                      {t('contactus.successStatus')}
                    </Typography>
                  </Box>
                  {xSmallScreen ? null : <div style={{ paddingRight: '20px' }} />}
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
            {/* First Block */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {matches ? null : (
                <Typography
                  className={classes.requestCompanyTextStyle}
                  style={{
                    fontFamily: 'Satoshi',
                    color: '#08070D',
                    fontWeight: '400',
                    marginRight: '5px'
                  }}
                >
                  {t('exploreAllCompanies.requestCompanyText')}
                </Typography>
              )}
              <Typography
                className={classes.requestCompanyTextStyle}
                style={{
                  fontFamily: 'Satoshi',
                  color: '#08070D',
                  fontWeight: '600',
                  cursor: 'pointer',
                  alignItems: 'center',
                  fontSize: '14px'
                }}
                onClick={() => {
                  setDisplayPopoverSubmitCopany(true);
                }}
              >
                <span
                  style={{
                    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                >
                  {matches
                    ? t('exploreAllCompanies.requestCompanyBtnMobile')
                    : t('exploreAllCompanies.requestCompanyBtn')}
                </span>
              </Typography>
            </div>

            {/* Second Block */}
            {process.env.REACT_APP_ENABLE_COMPANY_DOCUMENT_UPLOAD === 'true' ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Typography
                  className={classes.requestCompanyTextStyle}
                  style={{
                    fontFamily: 'Satoshi',
                    color: '#08070D',
                    fontWeight: '600',
                    cursor: 'pointer',
                    alignItems: 'center'
                  }}
                  onClick={() => {
                    navigate('/upload-company-document');
                  }}
                >
                  <span
                    style={{
                      backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >
                    Upload Company Data
                  </span>
                </Typography>
              </div>
            ) : null}
          </div>
        </Grid>
        {matches ? null : (
          <Grid item xs={12} className={classes.extfilterPadding}>
            <ExternalFiltersDesktop handleCompanyClick={handleCompanyClick} />
          </Grid>
        )}
        <Dialog
          open={displayFilterPopover}
          classes={{ paper: classes.dialogPaper }}
          fullWidth={true}
          maxWidth="md"
        >
          <Box p={0} width="100%">
            <DialogContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography style={{ fontWeight: 600, fontSize: '18px', fontFamily: 'satoshi' }}>
                  {t('externalFilter.filter')}
                </Typography>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <CloseIcon style={{ fontSize: '34px' }} onClick={handleCloseOpen} />
                </Box>
              </Box>
              <ExternalFilter />
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Button
                  style={{
                    marginBottom: '10px',
                    marginRight: '10px',
                    textTransform: 'none',
                    backgroundColor: '#FDF6F6',
                    border: '1px solid #FE4D4D',
                    color: '#FE4D4D',
                    padding: '0.5px 18px'
                  }}
                  className={classes.button}
                  onClick={handleClearAll}
                >
                  {t('externalFilter.clearAll')}
                </Button>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <Button
                    style={{
                      marginBottom: '10px',
                      marginRight: '10px',
                      textTransform: 'none',
                      border: '1px solid #004CFF',
                      padding: '0.5px 18px'
                    }}
                    className={classes.button}
                    variant="outlined"
                    onClick={handleApplyFilter}
                  >
                    {t('externalFilter.apply')}
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
        <RequestCompanyPopover
          displayPopoverSubmitCopany={displayPopoverSubmitCopany}
          setDisplayPopoverSubmitCopany={setDisplayPopoverSubmitCopany}
        />
        <Grid item className={classes.extfilterPadding}>
          <AppeliedFilters />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            height: exploreHeight,
            overflow: 'auto',
            display: 'grid',
            alignContent: 'flex-start'
          }}
          className={classes.exploreCompGrid}
        >
          <div>
            <Grid container>
              {loading ? (
                <>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.4}
                      key={index}
                      sx={{ flexGrow: 1, paddingTop: '10.5px' }}
                    >
                      <Card
                        sx={{
                          maxWidth: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          border: '1px solid #F0F0F0',
                          borderRadius: '10px',
                          marginLeft: '7.5px',
                          marginRight: '7.5px',
                          boxShadow: '0px 2px 4px 0px #0000000D'
                        }}
                        style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                        key={index}
                        elevation={0}
                      >
                        <Grid style={{ display: 'flex', alignItems: 'center' }}>
                          <Skeleton
                            variant="circular"
                            animation="wave"
                            style={{ margin: 10 }}
                            width={50}
                            height={50}
                          />
                          <Skeleton
                            variant="rounded"
                            animation="wave"
                            style={{ margin: 10 }}
                            width="40%"
                            height={10}
                          />
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
                </>
              ) : (
                companyListData.length != 0 &&
                companyListData[explorePagination.pageNumber - 1][0] &&
                companyListData[explorePagination.pageNumber - 1].map(
                  ({ name, cin, images, hidden }) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.4}
                      key={cin}
                      sx={{ flexGrow: 1, paddingTop: '10.5px' }}
                    >
                      <Card
                        sx={{
                          maxWidth: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          border: '1px solid #F0F0F0',
                          margin: '7.5px',
                          borderRadius: '10px',
                          boxShadow: '0px 2px 4px 0px #0000000D',
                          '&:hover': { backgroundColor: hidden ? 'none' : '#EFF3FC' },
                          backgroundColor: hidden ? '#CCCCCC' : 'inherit',
                          opacity: hidden ? 0.5 : 1
                        }}
                        style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                        key={name}
                        elevation={0}
                        onClick={() => (hidden ? null : handleCompanyClick(cin, name))}
                      >
                        <CardActionArea sx={{ flexGrow: 1, display: 'flex' }}>
                          {images && images.logo ? (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '55px',
                                height: '55px',
                                paddingLeft: '10px'
                              }}
                            >
                              <img
                                src={images.logo}
                                alt="Logo"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'contain'
                                }}
                              />
                            </div>
                          ) : (
                            <BusinessIcon style={{ paddingLeft: '20px' }} />
                          )}
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography
                              style={{
                                fontFamily: 'Satoshi',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                color: hidden ? '#000000' : '#000000B2' // Use gray color if not whitelisted
                              }}
                            >
                              {name}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    // </Grid>
                  )
                )
              )}
              <div ref={scrollRef} />
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            // display: 'flex',
            // height: '50px',  //not showing filters for now
            display: 'none',
            height: '0px',
            justifyContent: 'center',
            paddingTop: '0px'
          }}
        >
          {/* // check the below since now it is an object */}
          {displayedCompanies < companyListData.length && (
            <Button onClick={handleShowMore} style={{ textTransform: 'none' }}>
              <Typography style={{ fontFamily: 'Satoshi', fontWeight: 'bold', fontSize: '12px' }}>
                See more
              </Typography>
              <SeeMore style={{ paddingLeft: '5px' }} />
            </Button>
          )}
        </Grid>
        <Grid item xs={12} className={classes.paginationGrid}>
          <Pagination
            shape="rounded"
            count={totalPages}
            onChange={(e, val) => {
              handlePageChange(e, val);
            }}
            size={xSmallScreen ? 'small' : 'medium'}
            page={explorePagination.pageNumber ? explorePagination.pageNumber : 1}
          />
        </Grid>
        <Grid item xs={12} style={{ height: '20px' }} />
      </Grid>
      {/* {matches ? <div style={{ height: '10px' }} /> : null} */}
    </>
  );
};

export default ExploreCompanies;
